<template>
  <!-- 未结订单 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;">
      <div class="el-lt order" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="采购订单" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="楦号" :class="$i18n.locale">
              <el-input v-model="form.lastShapeCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select v-model="form.style" value-key="id" clearable filterable multiple>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU名称" :class="$i18n.locale">
              <el-input v-model="form.operatorGroup" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购员" :class="$i18n.locale">
              <el-input v-model="form.purchasePersonnel" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select v-model="form.vendorId" value-key="id" multiple clearable filterable>
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Operator" :class="$i18n.locale">
              <el-input v-model="form.operatorUser" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="创建日期" :class="$i18n.locale">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-checkbox v-model="form.isSecurity" true-label="1" false-label="" class="ml-3">含提前备货订单</el-checkbox>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="scm_uncleared_order" :params="queryParameter" />
            <ImportButton export-key="scm_uncleared_order_img" btn-type="primary" :params="queryParameter" export-name="按图片导出" :before-export="handleBeforeExport" />
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
      show-summary
      :summary-method="getSummaries"
      :row-style="rowStyle"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <el-table-column
        prop="purchaseOrderCode"
        label="采购订单"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="factory"
        label="供应商"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="styleImage"
        align="center"
        sortable
        label="图片"
        width="160"
      >
        <template slot-scope="scope">
          <img :src="scope.row.styleImage" alt="暂无图片" style="width: 50px;height: 50px">
        </template>
      </el-table-column>

      <el-table-column
        prop="productPosition"
        label="新/旧"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="operatorGroupUser"
        label="BU-Operator"
        width="130"
        align="center"
        sortable
      />
      <el-table-column
        prop="style"
        label="style"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="number"
        label="翻单数量"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="unCollectNumber"
        label="未出货数量"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        v-for="(item,index) in PlatFormAndSiteData.platformSiteList"
        :key="index"
        :prop="item"
        :label="item"
        width="130"
        align="center"
      >
        <!-- <template >
          <template v-for="(item2,key2) in PlatFormAndSiteData.platformSiteNumberList">
            <span v-if="key2 === key" v-html="item2" />
          </template>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="productStatus"
        label="在产状态"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="planDeliveryDate"
        label="计划评审到货时间"
        width="160"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right"
            trigger="click"
            @show="_listOpinionFeedbackLog(scope.row.purchaseOrderCode,3)"
          >
            <el-table :data="opinionFeedbackDatas" max-height="250px" style="width: 100%">
              <el-table-column type="index" width="80" label="序号" align="center" />
              <el-table-column width="150" property="opinionFeedback" label="计划评审到货时间" align="center" />
              <el-table-column width="100" property="createByName" label="操作人" align="center" />
              <el-table-column width="150" property="createTime" label="操作时间" align="center" />
            </el-table>
          </el-popover>
          <div v-popover:popover>{{ scope.row.planDeliveryDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="feedbackRemark"
        label="采购反馈出货时间"
        width="190"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right"
            trigger="click"
            @show="_VendorDeliveryFeedback(scope.row.purchaseOrderCode)"
          >
            <el-table :data="Vendordatas" max-height="250px" style="width: 100%">
              <el-table-column width="80" property="orderNum" label="序号" align="center" />
              <el-table-column width="100" property="vendorDeliveryFeedback" label="交期反馈出货时间" align="center" />
              <el-table-column width="100" property="createByName" label="操作人" align="center" />
              <el-table-column width="155" property="createTime" label="操作时间" align="center" />
              <el-table-column width="155" property="deliveryRemark" label="备注" align="center" />
            </el-table>
          </el-popover>
          <div v-popover:popover>{{ scope.row.feedbackRemark }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="operateFeedback"
        label="运营反馈意见"
        width="180"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right"
            trigger="click"
            @show="operateContentDetail(scope.row.purchaseOrderCode)"
          >
            <el-table :data="opinionFeedbackDatas" max-height="250px" style="width: 100%">
              <el-table-column type="index" width="80" label="序号" align="center" />
              <el-table-column width="120" property="opinionFeedback" label="运营反馈意见" align="center" />
              <el-table-column width="100" property="createByName" label="操作人" align="center" />
              <el-table-column width="150" property="createTime" label="操作时间" align="center" />
            </el-table>
          </el-popover>
          <div v-popover:popover>{{ scope.row.operateFeedback }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseFeedback"
        label="采购反馈意见"
        width="180"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right"
            trigger="click"
            @show="purchaseContentDetail(scope.row.purchaseOrderCode)"
          >
            <el-table :data="opinionFeedbackDatas" max-height="250px" style="width: 100%">
              <el-table-column width="80" type="index" label="序号" align="center" />
              <el-table-column width="120" property="opinionFeedback" label="采购反馈意见" align="center" />
              <el-table-column width="100" property="createByName" label="操作人" align="center" />
              <el-table-column width="150" property="createTime" label="操作时间" align="center" />
            </el-table>
          </el-popover>
          <div v-popover:popover>{{ scope.row.purchaseFeedback }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lastShapeCode"
        label="楦号"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="placeOrderTime"
        label="下单时间"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        prop="seasonI18"
        label="季节"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="purchasePersonnel"
        label="采购员"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="categoryName3"
        label="三级类别"
        width="170"
        align="center"
        sortable
      />
      <!-- <el-table-column
        prop="currencyTypeI18"
        label="币种"
        width="100"
        align="center"
        sortable
      /> -->
      <el-table-column prop="currencyType" label="币种" width="120" align="center" sortable>
        <template slot-scope="scope">
          {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="unitPrice"
        label="单价"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="occupyPrice"
        label="占用资金"
        width="160"
        align="center"
        sortable
      />
      <el-table-column
        prop="outStockDay"
        label="如不按期交货缺货天数"
        width="190"
        align="center"
        sortable
      />
      <el-table-column
        prop="lossSalesAmount"
        label="缺货的销售额损失"
        width="180"
        align="center"
        sortable
      />
      <el-table-column
        fixed="right"
        align="center"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <el-button v-if="scope.row.isRedUncleared===0" type="text" size="small" @click="markRed(scope.row,1)">飘红</el-button>
          <el-button v-else-if="scope.row.isRedUncleared===1" type="text" size="small" @click="markRed(scope.row,0)">恢复</el-button>
          <el-button type="text" size="small" @click="handelpurchase(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <pagination
        :pager="pager"
        :page-sizes="[10, 50, 200, 500]"
        @pagination="_unclearedOrderPage"
      />
    </div>
    <el-dialog title="修改" :visible.sync="addVisible" width="450px">
      <el-form ref="addForm" :model="addForm" label-width="170px">
        <el-form-item label="采购订单" prop="theme">
          <el-input v-model="addForm.purchaseOrderCode" placeholder="请输入" disabled />
        </el-form-item>
        <el-form-item label="生产状态" prop="theme">
          <el-input v-model="addForm.productStatus" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="如不按期交货缺货天数" prop="theme">
          <el-input v-model="addForm.outStockDay" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="缺货的销售额损失" prop="daysOfArrival">
          <el-input v-model="addForm.lossSalesAmount" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="运营反馈意见" prop="operateFeedback">
          <el-input v-model="addForm.operateFeedback" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="采购反馈意见" prop="purchaseFeedback">
          <el-input v-model="addForm.purchaseFeedback" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="计划评审到货时间" prop="planDeliveryDate">
          <el-date-picker
            v-model="addForm.planDeliveryDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button :loading="addLoading" type="primary" @click="handleAddEditSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { VendorDeliveryFeedback, queryVendorList, getPlatFormAndSiteNumberByUncleared, listOpinionFeedbackLog, updateOrderOutStockInfo, unclearedOrderPage, queryStyleList, updateUnclearedOrderIsRed } from '@/api/scm-api'
import Pagination from '@/components/Pagination'
import ImportButton from '@/components/ExportFile'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
export default {
  components: { Pagination, ImportButton },
  mixins: [commodityInfoDict],
  data() {
    return {
      planDeliveryDate: false,
      Vendordatas: [],
      opinionFeedbackDatas: [],
      addVisible: false,
      addLoading: false,
      editRow: {},
      companyOptions: [],
      vendorOptions: [],
      TableLoading: false,
      showRow: false, // 切换显示input
      tableDatas: [],
      styleOptions: [],
      PlatFormAndSiteData: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
        vendorId: [],
        style: [],
        date: []
      },
      addForm: {
        purchaseFeedback: '',
        purchaseOrderCode: '',
        productStatus: '',
        outStockDay: '',
        lossSalesAmount: ''
      }
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      const [placeOrderStartTime, placeOrderEndTime] = this.form.date || []
      return Object.assign({}, this.pager, this.form, { placeOrderStartTime, placeOrderEndTime })
    }
  },
  mounted() {
    this._unclearedOrderPage(this.queryParameter)
    this._queryStyleList()
    this._queryVendorList()
  },
  methods: {
    rowStyle({ row, rowIndex }) {
      const stylejson = {}
      if (row.isRedUncleared === 1) {
        stylejson.color = 'red'
        return stylejson
      } else {
        return ''
      }
    },
    // #606266
    async markRed(row, flag) {
      const { purchaseOrderCode } = row
      const { code } = await updateUnclearedOrderIsRed({ purchaseOrderCode, isRedUncleared: flag })
      code === 0 ? this.$message.success('操作成功') : this.$message.success('操作失败')
      this.queryClick()
    },
    // 供应商交期反馈原因及时间
    async _VendorDeliveryFeedback(purchaseOrderCode) {
      const { datas } = await VendorDeliveryFeedback(purchaseOrderCode)
      this.Vendordatas = datas
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if ((index >= 7 && index <= 15) || (index >= 26 && index <= 28)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              prev = (prev + '').indexOf('.') === -1 ? prev : Number(prev.toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    handleBeforeExport() {
      if (!this.form.style.length) {
        this.$message({
          message: '请选择style条件查询后导出',
          type: 'warning'
        })
        return false
      }
    },

    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 点击查询获取信息
    queryClick(page) {
      this.queryParameter.vendorId = this.form.vendorId ? this.form.vendorId.join(',') : ''
      this.pager.current = 1
      this._unclearedOrderPage(this.queryParameter)
      // this._getPlatFormAndSiteNumberByUncleared(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.queryClick(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 默认查询
    async _unclearedOrderPage(pagers) {
      try {
        this.TableLoading = true
        pagers && Object.assign(this.pager, pagers)
        this.queryParameter.style = this.form.style ? this.form.style.join(',') : ''
        this.queryParameter.vendorId = this.form.vendorId ? this.form.vendorId.join(',') : ''
        const { datas } = await unclearedOrderPage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
        this._getPlatFormAndSiteNumberByUncleared(this.queryParameter)
      } finally {
        this.TableLoading = false
      }
    },
    // 获取平台站点
    async _getPlatFormAndSiteNumberByUncleared(pagers) {
      this.queryParameter.style = this.form.style ? this.form.style.join(',') : ''
      this.queryParameter.vendorId = this.form.vendorId ? this.form.vendorId.join(',') : ''
      pagers && Object.assign(this.pager, pagers)
      const { datas } = await getPlatFormAndSiteNumberByUncleared(this.queryParameter)
      this.PlatFormAndSiteData = datas
      this.platformSiteNumberList = datas.platformSiteNumberList
      var arr = []
      this.tableDatas.map(e => {
        this.platformSiteNumberList.map(v => {
          if (e.purchaseOrderCode === v.purchaseOrderCode) {
            const obj = JSON.parse(JSON.stringify(v))
            delete obj.purchaseOrderCode
            e = { ...e, ...obj }
          }
        })
        arr.push(e)
      })
      this.tableDatas = arr
    },
    handelpurchase(row) {
      this.addVisible = true
      const { purchaseOrderCode, productStatus, outStockDay, lossSalesAmount, operateFeedback, purchaseFeedback, planDeliveryDate } = row
      planDeliveryDate ? this.planDeliveryDate = true : this.planDeliveryDate = false
      this.addForm = { purchaseOrderCode, productStatus, outStockDay, lossSalesAmount, operateFeedback, purchaseFeedback, planDeliveryDate }
    },
    handleAddEditSubmit() {
      this.$refs.addForm.validate(async(valid) => {
        if (valid) {
          try {
            this.addLoading = true
            this.addForm.flag = 1
            const { msg } = await updateOrderOutStockInfo(this.addForm)
            this.$message.success(msg)
            this.addVisible = false
            this._unclearedOrderPage(this.logistListForm)
          } finally {
            this.addLoading = false
          }
        }
      })
      // this._updateOrderOutStockInfo()
    },
    // 供应商交期时间记录查询
    purchaseContentDetail(orderCode, type) { // 采购
      const type2 = 2
      this._listOpinionFeedbackLog(orderCode, type2)
    },
    // 供应商交期时间记录查询
    operateContentDetail(orderCode, type) { // 运营
      const type1 = 1
      this._listOpinionFeedbackLog(orderCode, type1)
    },
    async _listOpinionFeedbackLog(params, type) {
      const { datas } = await listOpinionFeedbackLog(params, type)
      this.opinionFeedbackDatas = datas
    }
  }
}
</script>
<style lang="scss" scope>
.specialColor{
    color:red;
  }
  .el-tooltip__popper{max-width:20%;}
  .el-tooltip__popper,.el-tooltip__popper.is-dark{background:#e6e6e6 !important;
    color: #303133 !important;}
    .order {
  .el-range-input {
    width: 90px !important;
  }
  .el-range-separator {
    width: 6% !important;
  }
  .el-date-editor {
    width: 100% !important;
  }
}
</style>
